import React from "react";
import styled from "styled-components";
import Layout from "../components/layout";
import SEO from "../components/seo";
import HeaderInfoPage from "../components/HeaderInfoPages/HeaderInfoPages";

const DataItems = [
    {
        title:"Media Kit (.pdf)",
        size:"317 KB",
        linkHref:"/press-kit/sajdah-media-kit.pdf",
        icon:"/company-info.png",
        type:""
    },
    {
        title:"Brand Guidelines (.pdf)",
        size:"18 MB",
        linkHref:"/press-kit/sajdah-brand-guidelines.pdf",
        icon:"/logo-sajdah.svg",
        type:""
    },
    {
        title:"Browse Assets (Dropbox)",
        size:"2 GB",
        linkHref:"https://www.dropbox.com/sh/azgl6kzgwqyni1n/AACsyKyWc7xOkymGCZmpM5ima?dl=0",
        icon:"/product-photos.png",
        type:""
    }
]

const SeoData = {
    Title: "Press Kit | Sajdah - The World's First Smart Educational Prayer Rug",
    Keywords: [`Press`, `Kit`, `Sajdah`, `Prayer`, `Rug`, `Islam`, `Smart`],
    Description: "Press Kit for Sajdah, the world's first smart educational prayer rug that teaches new Muslims and children the second most important pillar in Islam.",
    Image: "https://getsajdah.com/sajdah_og_image_thumb.jpg",
    url: "https://getsajdah.com/press-kit",
    titleTemplate: "Press Kit | Sajdah - The World's First Smart Educational Prayer Rug"
};

const PressKit = () => (
  <Layout>
    <SEO
        description={SeoData.Description}
        keywords={SeoData.Keywords}
        title={SeoData.Title}
        image={SeoData.Image}
        url={SeoData.url}
        titleTemplate={SeoData.titleTemplate}
    />
    <HeaderInfoPage title="Press Kit" />
    <Wrapper>
        <Container>
            <Title>
                Thank You for Your Interest in Sajdah!
            </Title>
            <Subhead>
                If you have press inquiries or if you’d like to write about us, email us at <a href="mailto:hello@getsajdah.com"> hello@getsajdah.com </a>
                We’d love to chat with you!
            </Subhead>
            <ItemsWrapper>
                {
                DataItems.map((item,index) => {
                    let download
                    item.type === "download" ? download = true : download = false
                    return (
                        <WrapperItem key={index}>
                            <a href={item.linkHref} download={download}>
                                <Item>
                                    <Icon>
                                        <img src={item.icon} alt="item-icon" />
                                    </Icon>
                                    <CardTitle>{item.title}</CardTitle>
                                    <CardText>{item.size}</CardText>
                                </Item>
                            </a>
                        </WrapperItem>
                    )
                }) 
                }
            </ItemsWrapper>
        </Container>
    </Wrapper>
  
  </Layout>
);

const Wrapper = styled.div`
    width:100%;
    height:auto;
    background: #f5f7fd;
`

const Container = styled.div`
    width:100%;
    max-width: 1200px;
    padding:100px 0 60px;
    text-align:center;
    margin:0 auto;
`

const Title = styled.h2`
    color: #292929;
    font-size: 48px;
    font-weight: 500;
    font-family: Poppins, sans-serif;
    margin-bottom:24px;
    @media (max-width:470px) {
        font-size: 32px;
    }
`

const Subhead = styled.p`
    font-family: Poppins, sans-serif;
    margin: 15px 0 0;
    a {
        color: #009298;
        text-decoration:none;
    }
`
const ItemsWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content:space-between;
    width:100%;
    margin:32px auto 0;
    @media (max-width:470px) {
        flex-direction:column;
        align-items:center;
    }
`

const Item = styled.div`
    text-align: center;
    padding: 10% 5%;
    position: relative;
    transition: all .5s ease;
    top: 0;
    border-radius: 5px;
    background: #fff;
    :hover {
        background-color: #008e8e;
        transform:translate(0,-16px);
        p {
            color:white;
            text-decoration:none;
        }
    }
`

const Icon = styled.div`
    width: 150px;
    height: 150px;
    position: relative;
    vertical-align: top;
    background: #f5f7fd;
    border-radius: 50%;
    pointer-events: none;
    display:flex;
    align-items:center;
    justify-content:center;
    margin:0 auto;
   img {
      max-width: 70%;
      max-height: 70%;
    }
`

const WrapperItem = styled.div`
    flex: 0 0 30%;
    max-width: 30%;
    margin-bottom: 15px;
    margin-top: 15px;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    a {
        text-decoration:none;
    }
    @media (max-width:768px) {
        flex: 0 0 26%;
        max-width: 26%;
    }
    @media (max-width:470px) {
        flex: 0 0 75%;
        max-width: 75%;
    }
    
`

const CardTitle = styled.p`
    font-family: Poppins, sans-serif;
    color: #292929;
    margin-bottom:12px;
    text-decoration:none;
`

const CardText = styled.p`
    font-family: Poppins, sans-serif;
    color: #292929;
    text-decoration:none;
`

export default PressKit;
